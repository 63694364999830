<template>
  <div class="home ma-2">
    <v-card class="ma-2">
      <v-card-title>Pagina não encontrada</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <p>
              Pagina solicitada não encontrada clique
              <a onclick="history.back()">AQUI</a> para voltar
            </p>
          </v-col>
        </v-row>
        <v-row v-if="!getLogged">
          <v-col>
            <p>
              Não logado, redirencionando para o Login em
              {{ count == 0 ? "Redirecionando...." : count }}
            </p>
            <a @click="$router.push({ path: '/login' })">Clique aqui para logar</a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NotFound",
  computed: {
    ...mapGetters(["getLogged"]),
  },
  data: () => ({
    timer: null,
    count: 5,
  }),
  created() {
    if (!this.getLogged) {
      // this.timer = setInterval(() => {
      //   if (this.count == 0) {
      //     this.$router.push({ path: "/login" });
      //   }
      //   this.count--;
      // }, 1000);
      this.$router.push({ path: "/login" });
    }
  },
};
</script>
