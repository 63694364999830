<template>
  <v-row align="center" justify="center">
    <span class="bgL"></span>
    <v-col cols="auto">
      <v-card ref="form" max-width="400" id="cardLogin">
        <v-card-text >
          <v-card-title class="headline"> Login do Sistema </v-card-title>
          <v-text-field
            ref="userName"
            v-model="user.userName"
            :rules="[() => !!user.userName || 'Campo obrigatório']"
            label="Usuário"
            placeholder="Usuário"
            required
          ></v-text-field>
          <v-text-field
            ref="password"
            v-model="user.password"
            :rules="[() => !!user.password || 'Campo obrigatório']"
            label="Senha"
            placeholder="Senha"
            @keydown.enter="login"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="login"> Entrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      user: {
        userName: "",
        password: "",
      },
      users: [],
    };
  },
  methods: {
    login() {
      this.$http
        .post(`/login`, this.user)
        .then((resp) => {
          if (resp.data) {
            let token = resp.data.token;
            this.$store.commit("setToken", token);
            // this.$router.push({ path: this.$store.getters.getDefaultRoute });
            // this.getPermissions();
            this.getUser();
            this.sendPath()
            this.$store.dispatch("loadGrupos");
          }
        })
        .catch(() => {
          
        });
    },
    sendPath(){
      let route = this.$store.getters.getStartNavigation;
      // se a rota for a raiz ou login ou vazia, redireciona para a rota padrão
      if(route == '/' || route == '/login'|| route == ''){
        this.$router.push({ path: this.$store.getters.getDefaultRoute });
      }else{
        // se não, redireciona para a rota que estava antes de logar
        this.$router.push({ path: route });
      }
    },
    getUser() {
      this.$http(`/login/`)
      // this.$http(`/usuario/${this.$store.state.user.id}`)
        .then((resp) => {
          if (resp.data) {
            // console.log(resp.data);
            delete resp.data.password
            this.$store.commit("setUser", resp.data);
            // this.$router.push({ path: this.$store.getters.getDefaultRoute });
          }
        })
        .catch(() => {
        });
    },
  },

  created() {
    let token = localStorage.getItem("cargasD");
    if (token) {
      this.$store.commit("setToken", token);
      this.$store.commit("setLogged", true);
      this.getUser();
      this.sendPath();
    } else {
      if (!this.$router.history.current.path === "/login") {
        this.$router.push({ path: "/login" });
      }
    }
  },
};
</script>

<style>
.bgL {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* background: url( '../assets/background.jpg') no-repeat center center; */
    background-size: cover;
    
    transform: scale(1.1);
  }
</style>